import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Button icons=Add wrap=flex",
        "componentName": "Button",
        "icons": "Add",
        "wrap": "flex"
      }}>{`<Button onClick={() => alert('hi')} iconLeft={Add}>
  My button
</Button>
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Button" mdxType="Props" />
    <h2 {...{
      "id": "appearance",
      "style": {
        "position": "relative"
      }
    }}>{`Appearance`}</h2>
    <p><inlineCode parentName="p">{`default: primary`}</inlineCode></p>
    <p>{`To differentiate between levels of emphasis, there are 4 possible appearances a
button can have.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Button appearance="primary">Primary</Button>
<Button appearance="secondary">Secondary</Button>
<Button appearance="plain">Plain</Button>
<Button appearance="outline">Outline</Button>
`}</code></pre>
    <h2 {...{
      "id": "width",
      "style": {
        "position": "relative"
      }
    }}>{`Width`}</h2>
    <p><inlineCode parentName="p">{`default: auto`}</inlineCode></p>
    <p>{`By default, buttons will only take up the space necessary in order to fit its
text content and the needed padding. In order for your button to fill the
complete surrounding element, you can adjust the `}<inlineCode parentName="p">{`width`}</inlineCode>{` parameter.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Button width="full">Full width button</Button>
`}</code></pre>
    <h2 {...{
      "id": "size",
      "style": {
        "position": "relative"
      }
    }}>{`Size`}</h2>
    <p><inlineCode parentName="p">{`default: large`}</inlineCode></p>
    <p>{`Buttons come in different shapes and sizes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Button size="large">Large button</Button>
<Button size="small">Small button</Button>
`}</code></pre>
    <h2 {...{
      "id": "loading",
      "style": {
        "position": "relative"
      }
    }}>{`Loading`}</h2>
    <p><inlineCode parentName="p">{`default: false`}</inlineCode></p>
    <p>{`To indicate to a user that something is loading, you can pass in the `}<inlineCode parentName="p">{`loading`}</inlineCode>{`
prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex",
        "wrap": "flex"
      }}>{`<Button loading={false}>Default button</Button>
<Button loading={true}>Loading button</Button>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      